<template>
  <v-container fluid class="flowbody pb-16">
    <v-container>
      <flow-header title="Are you currently living there?"></flow-header>
      <v-card elevation="0" class="pt-0 pt-sm-3" color="lightgray">
        <v-card-actions class="mt-7">
          <v-row class="align-stretch flex-column flex-sm-row">
            <v-col
              ><v-card
                elevation="0"
                color="#F5F7FA"
                class="rounded-lg pa-5 spontay-card-button"
                height="100%"
                link
                @click="storePurposeAndContinue('Living there')"
              >
                <v-col
                  ><v-img
                    alt="spontay-logo"
                    src="@/assets/images/icon-4.svg"
                    height="44"
                    contain
                    eager
                    position="left"
                /></v-col>
                <v-card-title class="spontay-card-title">Yes</v-card-title>
                <v-card-text
                  >I own this house and I live in it full time.</v-card-text
                >
              </v-card>
            </v-col>
            <v-col>
              <v-card
                elevation="0"
                color="#F5F7FA"
                class="rounded-lg pa-5 spontay-card-button"
                link
                height="100%"
                @click="storePurposeAndContinue('For rent')"
              >
                <v-col
                  ><v-img
                    alt="spontay-logo"
                    src="@/assets/images/icon-5.svg"
                    height="44"
                    position="left"
                    contain
                    eager
                /></v-col>
                <v-card-title class="spontay-card-title"
                  >No, I’m renting it out</v-card-title
                >
                <v-card-text>
                  This house is rented out to tenants on a long term basis
                  (usually a year), or will be rented in the next 30 days
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card
                elevation="0"
                color="#F5F7FA"
                class="rounded-lg pa-5 spontay-card-button"
                link
                height="100%"
                @click="storePurposeAndContinue('It’s vacant')"
              >
                <v-col
                  ><v-img
                    alt="spontay-logo"
                    src="@/assets/images/icon-6.svg"
                    height="44"
                    position="left"
                    contain
                    eager
                /></v-col>
                <v-card-title class="spontay-card-title"
                  >It’s vacant</v-card-title
                >
                <v-card-text>
                  This house is currently vacant with no contents, or has
                  planned renovations which will make the home unoccupied.
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import FlowHeader from "@/components/FlowHeader";

export default {
  components: { FlowHeader },
  data: () => ({
    propertyPurpose: null,
  }),
  methods: {
    storePurposeAndContinue(type) {
      this.$store.commit("setPurpose", type);
      this.$router.push("/contactinformation");
      window.scrollTo(0, 0);
    },
  },
};
</script>
