<template>
  <v-container fluid style="background-color: #0a0f1e" class="py-16">
    <v-container class="pb-16" style="max-width: 1150px">
      <div class="mt-16 text-h6" style="color: white">TESTIMONIALS</div>
      <div class="text-h3 mt-10" style="color: white">
        See what our customers are saying
      </div>

      <div
        class="carousel mt-16"
        style="cursor: pointer"
      >
        <transition name="fade">
          <v-img
            contain
            class="carousel-image"
            :src="require(`../assets/images/${currentSlide.image}`)"
            v-show="showImage"
            eager
          />
        </transition>

        <div class="carousel-text pa-10">
          <div class="text-h5 mb-6">{{ currentSlide.text }}</div>
          <div class="text-body-h2 mb-16">{{ currentSlide.subText }}</div>
          <div class="text-body-h2">{{ currentSlide.personalInfo }}</div>
          <v-img
            src="@/assets/images/left-arrow.svg"
            class="arrow"
            style="right: 64px"
            @click="goToPreviousSlide"
          />
          <v-img
            src="@/assets/images/right-arrow.svg"
            class="arrow"
            @click="goToNextSlide"
          />
        </div>
      </div>
    </v-container>
  </v-container>
</template>

<script>
const FADE_TIMEOUT = 300;
const SLIDE_CHANGE_TIMEOUT = 6000;

export default {
  data() {
    return {
      slideIdx: 1,
      showImage: true,
      slides: [
        {
          image: 'testimonial-1.jpg',
          text: 'I wanted to renovate my kitchen, but didn’t want to take another loan. ',
          subText: 'Spontay was just the thing I was looking for!',
          personalInfo: 'Kate - Austin, TX'
        },
        {
          image: 'testimonial-2.jpg',
          text: 'Our insurance claim got denied, we needed cash to fix our roof. Spontay really came through.',
          subText: 'The process was smooth & easy. It was a big weight off our shoulders.',
          personalInfo: 'Jose - Dallas, TX'
        },
        // Add more slides if needed
      ]
    }
  },
  mounted() {
    setInterval(() => {
      this.goToNextSlide()
    }, SLIDE_CHANGE_TIMEOUT)
  },
  computed: {
    currentSlide() {
      return this.slides[this.slideIdx]
    }
  },
  methods: {
    goToPreviousSlide() {
      this.showImage = false
      setTimeout(() => {
        if (this.slideIdx === 0) {
          this.slideIdx = this.slides.length - 1
        } else {
          this.slideIdx--
        }

        this.$nextTick(() => {
          this.showImage = true
        })
      }, FADE_TIMEOUT)
    },
    goToNextSlide() {
      this.showImage = false
      setTimeout(() => {
        if (this.slideIdx === this.slides.length - 1) {
          this.slideIdx = 0
        } else {
          this.slideIdx++
        }
        this.$nextTick(() => {
          this.showImage = true
        })
      }, FADE_TIMEOUT)
    },
  },
}
</script>

<style scoped>
.arrow {
  width: 52px;
  position: absolute;
  bottom: -24px;
  right: 0;
  cursor: pointer;
}

.carousel {
  position: relative;
}

.carousel-image {
  max-width: 70%;
}

.carousel-text {
  max-width: 60%;
  background-color: #FFC001;
  position: absolute;
  bottom: -40px;
  right: 0;
}

@media (max-width: 800px) {
  .carousel-text {
    bottom: -120px;
  }
}

@media (max-width: 600px) {
  .carousel-image {
    max-width: 100%;
  }
  .carousel-text {
    position: relative;
    
    max-width: 100%;
    bottom: unset;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
