<template>
  <layout-dialog
    ref="layoutDialog"
    title="What is Homeowners Insurance?"
    subtitle="H0-3 INSURANCE POLICY"
  >
    <v-container class="pb-10" style="max-width: 1100px;">
      <div class="text-body-1">
        Also known as homeowners insurance, HO-3 policies are the most common
        type of home insurance as they provide robust coverage options. In fact,
        estimates from the National Association of Insurance Commissioners state
        that about 80% of all single family home policies were HO-3s in 2017.
        But not all homeowners insurance policies are the same. Keep reading for
        an expert break down on everything you need to know about HO-3
        insurance, so you can make an informed decision on what policy will best
        safeguard your family and your home.
      </div>
      <div class="text-h3">Key takeaways:</div>
      <div class="text-body-1">
        <ul>
          <li>
            You need HO-3 insurance because it provides financial coverage
            should your home’s structure get damaged from natural disasters or
            other perils such as theft or fire.
          </li>
          <li>
            HO-3 insurance policies cover your dwelling, belongings and personal
            liability.
          </li>
        </ul>
      </div>
      <div class="text-h3">What is an HO-3 homeowners policy?</div>
      <div class="text-body-1">
        A homeowners insurance (HO-3) policy is a coverage plan that covers your
        home’s structure, your personal belongings and liability in the event of
        damage or injury. Typically, an HO-3 policy will also cover additional
        living expenses and protection for other structures on your property.
        Sometimes noted as a “special form” policy, it is purchased for
        traditional, standalone homes rather than other types of homes like
        duplexes or condominiums.
      </div>
      <div class="text-h3">Why do I need HO-3 coverage?</div>
      <div class="text-body-1">
        Homeowners need HO-3 insurance because it provides financial coverage
        should your home’s structure get damaged from natural disasters and
        other perils such as theft or fire. Throughout your life as a homeowner,
        many unexpected injuries can occur as well, which is why liability
        protection is included in your policy. HO-3 also protects your personal
        belongings, acting as a cushion for costly replacements or repairs.
        Because of the multiple tiers of protection, it’s usually required when
        getting a mortgage loan, as it helps to safeguard your (and your
        lender’s) monetary investment.
      </div>
      <div class="text-h3">What does an HO-3 policy cover?</div>
      <div class="text-body-1">
        To help you determine whether an HO-3 policy is right for you, it’s
        important to gain a full understanding of what is protected. As
        mentioned above, HO-3 insurance policies cover your dwelling, belongings
        and personal liability. Often, you will also be protected for additional
        living expenses as well, should you need to stay somewhere else while
        your home is being repaired. Depending on your provider, there may be
        other add-ons bundled within your policy, such as virtual maintenance
        services or smart home kits. There may even be discounts involved for
        committing to use greener appliances or installing protective smart home
        devices throughout your home (we do this too). For each category of
        coverage, there are intricacies you need to be aware of before signing
        any policy. Since each item has a different level of coverage,
        understanding the differences is key to properly protecting your home
        for everything from common damages to unthinkable disasters.
        <br /><br />
        <span style="font-size: 1.2em; line-height: 3em;"><b>Dwelling</b></span>
        <br />
        Dwelling insurance covers damage to your home’s structure, as well as
        any additional structures on your property. Additional structures
        include things like detached garages, sheds and fences. This category is
        under an open peril policy, meaning your structures are insured from all
        events (minus any named exclusions). Typical insurance exclusions for
        dwellings include floods, earthquakes, mudslides, sewer backups,
        ordinance updates and government seizures. If your home is located in a
        flood plain or earthquake zone, it may be worth purchasing additional
        protection to cover these perils.
        <br /><br />
        <span style="font-size: 1.2em; line-height: 3em;"><b>Personal property </b></span>
        <br />
        Another major part of your HO-3 policy is personal property protection.
        This coverage helps protect your belongings in the event of damage,
        theft or loss. While there are certain caveats to what items are covered
        and how big your policy limits are, some personal property clauses will
        cover listed items even if the damage occurs off your property. It’s
        standard practice for your insurance company to offer named peril
        protection for your items, meaning you are only covered for perils
        explicitly listed in your policy. These will often include artificial
        electrical current, cracking or other damage to pipes, damage from an
        aircraft or vehicle, explosion, falling objects, fire, freezing, hail,
        lightning, riots or civil commotion, smoke, theft, vandalism, volcanic
        activity, water or steam overflow, weight of snow or ice and wind. To
        get coverage for other perils and extended policy limits for expensive
        items, you may need to purchase add-ons to your policy.
        <br /><br />
        <span style="font-size: 1.2em; line-height: 3em;"><b>Liability </b></span>
        <br />
        Liability The last category of protection within your homeowners
        insurance policy is personal liability. Liability coverage means you
        will be financially shielded if “bodily injury” or “property damage”
        occurs on your property. This includes coverage for their medical
        expenses, legal fees, lost wages and even death benefits. However, like
        all categories in your policy, there are exceptions. For personal
        liability, any injuries inflicted intentionally to yourself or others
        are not covered.
      </div>
    </v-container>
  </layout-dialog>
</template>

<script>
import LayoutDialog from "./LayoutDialog";

export default {
  components: {
    LayoutDialog,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    show() {
      this.$refs.layoutDialog.show();
    },
  },
};
</script>

<style scoped>
.text-h3 {
  margin-top: 2em !important;
}

.text-body-1 {
  margin-top: 1em !important;
}
</style>