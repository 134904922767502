<template>
  <layout-dialog
    ref="layoutDialog"
     title="Privacy Policy"
    subtitle="SPONTAY, LLC"
  >
    <div
      id="policies"
      name="termly-embed"
      data-type="iframe"
      data-id="818a126e-da1e-45b6-aeeb-c63618027620"
    />
  </layout-dialog>
</template>

<script>
import LayoutDialog from "./LayoutDialog"

export default {
  components: {
    LayoutDialog
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    show() {
      this.$refs.layoutDialog.show()
      this.$nextTick(() => {
        const termsAndConditions = document.getElementById('policies')
        let js = document.createElement('script')
        js.id = 'termly-jssdk'
        js.src = "https://app.termly.io/embed-policy.min.js";

        termsAndConditions.parentNode.insertBefore(js, termsAndConditions);
      })
    }
  },
}
</script>

<style scoped>

</style>