<template>
  <v-container fluid class="flowbody">
    <v-container>
      <v-card
        elevation="0"
        min-height="60vh"
        color="lightgray"
        class="d-flex py-10 flex-column success-message justify-center"
      >
        <v-card-title
          ><v-icon x-large style="font-size: 100px; margin: auto" color="accent"
            >mdi-check-circle</v-icon
          ></v-card-title
        >
        <v-card-title class="text-h3 justify-center text-center"
          >All done!</v-card-title
        ><v-card-subtitle class="mt-6 text-h5 text-center"
          >We will contact you shortly with the next steps</v-card-subtitle
        >
        <!-- <v-card
          class="d-flex rounded-xl flex-column align-center pa-12 pa-sm-16"
          color="rgb(225, 241, 255)"
          style="font-weight: 500"
          elevation="0"
        >
          <div style="font-size: 1.4rem; font-weight: 700; margin-bottom: 2rem">
            Internal Results
          </div>
          <div>Address: {{ this.$store.state.address }}</div>
          <div>Ownership: {{ this.$store.state.ownership }}</div>
          <div>Furnishing: {{ this.$store.state.furnishing }}</div>
          <div>Property Purpose: {{ this.$store.state.purpose }}</div>
          <div>First Name: {{ this.$store.state.firstName }}</div>
          <div>Last Name: {{ this.$store.state.lastName }}</div>
          <div>Email: {{ this.$store.state.email }}</div>
        </v-card> -->
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  mounted() {
    // TODO: add facebook pixel done script
  }
};
</script>

<style scoped>
@media (max-width: 600px) {
  .success-message .text-h3 {
    font-size: 2rem !important;
  }
   .success-message .text-h5 {
    font-size: 1.1rem !important;
  }
}
</style>
