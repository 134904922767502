<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay>
    
    <v-card color="lightgray" >
      <v-btn
        elevation="0"
        fab
        color="lightgray"
        @click="close"
        class="mt-2 mr-sm-2"
        style="
          position: fixed;
          left: calc(100vw - 80px);
          top: 8px;
          z-index: 100;
        "
      >
        <v-icon large>mdi-close</v-icon>
      </v-btn>
      <v-container class="px-4">
        <v-spacer></v-spacer>
        <v-card
          elevation="0"
          color="lightgray"
          class="
            mt-16
            pb-6
            pb-sm-12
            pt-8
            pt-sm-14
            px-8 px-sm-10
            rounded-0
            d-flex
            flex-column
            align-center
            dialog-title
          "
          style="border: dashed 2px #4683D0!important;"
        >
          <v-img
            width="48"
            height="32"
            contain
            src="@/assets/images/logo-small.svg"
          ></v-img>
          <v-card-subtitle class="text-h6 mt-5"
            >{{subtitle}}
          </v-card-subtitle>
          <v-card-title class="text-h2 text-center"
            >{{title}}
          </v-card-title>
        </v-card>

        <v-container class="py-sm-16">
          <slot></slot>


            <contact-us></contact-us>

        </v-container>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ContactUs from "@/components/ContactUs";

export default {
  components: { ContactUs },
  props: {
    title: String,
    subtitle: String,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    show() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
