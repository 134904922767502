<template>
  <v-container class="py-10 py-sm-16" style="max-width: 1100px">
    <v-row>
      <v-col>
        <div class="text-h6 text-center">{{ subtitle }}</div>
        <div class="text-h2 text-center mt-10">
          {{ title }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
  },
};
</script>
