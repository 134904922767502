<template>
  <layout-dialog
    ref="layoutDialog" title="What is co-investing?" subtitle="HOW DOES IT WORK?"
  >
    <v-card max-width="1100" color="lightgray" elevation="0" class="mx-auto">
      <v-row v-for="banner in banners" :key="banner.id" class="pt-10">
        <v-col style="flex-grow: 0" class="px-6">
          <v-img eager max-width="278" class="mx-10" contain :src="banner.image"></v-img>
        </v-col>
        <v-col class="d-flex align-center">
          <v-card elevation="0" color="lightgray">
            <v-card-title class="text-h3">{{ banner.title }}</v-card-title>
            <v-card-text class="text-body-1">
              {{ banner.text }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-card elevation="0" color="lightgray" max-width="1100" class="mt-16 mx-auto">
      <v-card-title class="text-h2 text-center mb-8 pt-sm-14"
      >That’s where Spontay comes into the picture
      </v-card-title
      >
      <v-row
        v-for="sbanner in sbanners"
        :key="sbanner.id"
        class="pt-10"
      >
        <v-col style="flex-grow: 0" class="px-6">
          <v-img eager max-width="400" class="mx-10" contain :src="sbanner.image"></v-img>
        </v-col>
        <v-col class="d-flex align-center">
          <v-card elevation="0" color="lightgray">
            <v-card-title class="text-h3">{{ sbanner.title }}
            </v-card-title>
            <v-card-text class="text-body-1" v-html="sbanner.text">
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </layout-dialog>
</template>

<script>
import LayoutDialog from "./LayoutDialog";

export default {
  components: {
    LayoutDialog
  },
  data() {
    return {
      dialog: false,
      banners: [
        {
          id: "1",
          image: require("../../src/assets/images/co-investing1.svg"),
          title: "Your house is an investment",
          text: "It’s building up home equity over the years. Let’s say you’ve bought it for $200k. After 10 years you will sell it for $250k (or more!)",
        },
        {
          id: "2",
          image: require("../../src/assets/images/co-investing2.svg"),
          title: "Home equity is yours (but later)",
          text: "The 50k difference is the home equity you’ve build up over the years. Now the downside of this is that you can’t access these 50k without selling your house.",
        },
        {
          id: "3",
          image: require("../../src/assets/images/co-investing3.svg"),
          title: "What if you need money?",
          text: "You take a loan. Your credit score is checked, if everything goes smoothly you are stuck with high interest rates and monthly payments.",
        },
      ],
      sbanners: [
        {
          id: "1",
          image: require("../../src/assets/images/co-investing4.svg"),
          title: "We co-invest into your home. ",
          text: "We become become investors in your house (5-10%) and offer you the cash now (up to $10’000).<br><br>P.S. Don’t worry, we won’t show up at your doorstep",
        },
        {
          id: "2",
          image: require("../../src/assets/images/co-investing5.svg"),
          title: "You get the money you need",
          text: "<ul><li>You get the cash out of your future home equity</li><li>No need to take a loan, or to sell anything</li><li>No monthly payments</li></ul>",
        },
        {
          id: "3",
          image: require("../../src/assets/images/co-investing6.svg"),
          title: "We quietly exit (whenever you want)",
          text: "You can buy back our share whenever you get some extra cash. Or wait until you sell your house and pay us back then.",
        },
      ],
    }
  },
  methods: {
    show() {
      this.$refs.layoutDialog.show()
    }
  },
}
</script>

<style scoped>

</style>