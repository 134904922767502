<template>
    <v-row class="flow-header">
        <v-col class="aling-center d-flex flex-column pb-sm-4">
            <h4 class="mt-4 mt-sm-16 text-h4 text-center px-2">{{ title }}</h4>
            <h5
                class="mt-3 mt-sm-10 text-h5 gray--text text-center px-2"
                style="font-weight: 400;"
            >
                {{ subtitle }}
            </h5>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ["title", "subtitle"],
};
</script>

<style scoped>

 .myFont .flow-header h4 {
        font-size: 2rem!important;
        font-family: "maisonext"!important;
        color: #0A0F1E;
    }

    .flow-header h5 {
        color: #0A0F1E;
    }


@media (max-width: 600px) {
    .myFont .flow-header h4 {
        font-size: 1.7rem!important;
    }

     .myFont .flow-header h5 {
        font-size: 1.1rem!important;
    }
}
</style>