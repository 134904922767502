<template>
  <v-container fluid class="flowbody pb-16">
    <v-container>
      <flow-header
        title="Ok, now let’s talk about the insides of the house"
        subtitle="Do you have any special fixtures and furnishing?"
      ></flow-header>
      <v-card elevation="0" class="pt-0 pt-sm-3" color="lightgray">
        <v-card-actions class="mt-7">
          <v-row class="align-stretch flex-column flex-sm-row">
            <v-col>
              <v-card
                class="rounded-lg pa-5 spontay-card-button"
                link
                height="100%"
                @click="storeFurnishingAndContinue('No, it’s pretty basic')"
                elevation="0"
              >
                <v-col
                  ><v-img
                    eager
                    alt="spontay-logo"
                    src="@/assets/images/icon-1.svg"
                    class="shrink"
                    height="44"
                    contain
                    position="left"
                /></v-col>
                <v-card-title class="spontay-card-title"
                  >No, it’s pretty basic</v-card-title
                >
                <v-card-text
                  >Example: medium grade flooring, carpeting, or cabinets, and
                  other common details.</v-card-text
                >
              </v-card>
            </v-col>
            <v-col>
              <v-card
                class="rounded-lg pa-5 spontay-card-button"
                link
                height="100%"
                @click="storeFurnishingAndContinue('A few extras')"
                elevation="0"
              >
                <v-col
                  ><v-img
                    alt="spontay-logo"
                    src="@/assets/images/icon-2.svg"
                    class="shrink"
                    height="44"
                    contain
                    eager
                    position="left"
                /></v-col>
                <v-card-title class="spontay-card-title"
                  >A few extras</v-card-title
                >
                <v-card-text
                  >Example: high grade siding or roofing, built-in bookshelves,
                  bay windows, marble/granite counters.</v-card-text
                >
              </v-card>
            </v-col>
            <v-col>
              <v-card
                class="rounded-lg pa-5 spontay-card-button"
                link
                height="100%"
                @click="storeFurnishingAndContinue('Yes, it’s high-end')"
                elevation="0"
              >
                <v-col
                  ><v-img
                    alt="spontay-logo"
                    src="@/assets/images/icon-3.svg"
                    class="shrink"
                    height="44"
                    contain
                    eager
                    position="left"
                /></v-col>
                <v-card-title class="spontay-card-title"
                  >Yes, it’s high-end</v-card-title
                >
                <v-card-text
                  >Example: marble flooring, crystal chandeliers, commercial or
                  built-in appliances, ornate finishes.</v-card-text
                >
              </v-card>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import FlowHeader from "@/components/FlowHeader";

export default {
  components: { FlowHeader },
  data: () => ({
    furnishing: null,
  }),
  methods: {
    storeFurnishingAndContinue(type) {
      this.$store.commit("setFurnishing", type);
      this.$router.push("/propertypurpose");
      window.scrollTo(0, 0);
    },
  },
};
</script>