<template>
  <v-container fluid class="flowbody">
    <v-container>
      <flow-header
        title="First things first"
        subtitle="Are you the owner?"
      ></flow-header>
      <v-card elevation="0" class="pt-0 pt-sm-10" color="lightgray">
        <v-card-actions class="mt-7 justify-center flex-column flex-sm-row">
          <v-btn
            class="spontay-button-secondary spontay-button ma-2"
            width="200"
            x-large
            :block="$vuetify.breakpoint.xs"
            color="white"
            @click="storeOwnershipAndContinue('No')"
            >No</v-btn
          >
          <v-btn
            class="spontay-button ma-2"
            width="200"
            x-large
            :block="$vuetify.breakpoint.xs"
            color="accent"
            @click="storeOwnershipAndContinue('Yes')"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import FlowHeader from "@/components/FlowHeader";

export default {
  components: { FlowHeader },
  data: () => ({
    ownership: null,
  }),
  methods: {
    storeOwnershipAndContinue(type) {
      this.$store.commit("setOwnership", type);
      this.$router.push("/furnishing");
      window.scrollTo(0, 0);
    },
  },
};
</script>

