<template>
  <v-container fluid class="flowbody pb-16">
    <v-container>
      <flow-header
        title="Offer up to $100,000 cash plus all your home insurance needs"
        subtitle="We’ll contact you to discuss the details"
      ></flow-header>
      <v-card color="lightgray" elevation="0" class="pt-sm-8 pt-0">
        <!-- FORM -->
        <v-card-actions class="mt-4">
          <div style="max-width: 600px; margin: auto">
            <v-form ref="form" v-model="formIsValid">
              <v-row>
                <v-col>
                  <v-text-field
                    autofocus
                    v-model="firstName"
                    :rules="firstNameRules"
                    label="First Name"
                    @keyup.enter="tryAndStoreContactInfoAndContinue"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="lastName"
                    :rules="lastNameRules"
                    label="Last Name"
                    @keyup.enter="tryAndStoreContactInfoAndContinue"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="Email Address"
                @keyup.enter="tryAndStoreContactInfoAndContinue"
              >
              </v-text-field>
              <!-- <phone-field
                v-model="phone"
                label="Phone Number"
                required
                @keyup.enter="tryAndStoreContactInfoAndContinue"
              /> -->

              <v-btn
                :loading="isLoading"
                class="spontay-button mt-4"
                x-large
                block
                color="accent"
                @click="tryAndStoreContactInfoAndContinue"
              >
                Continue
              </v-btn>
            </v-form>
          </div>
        </v-card-actions>
        <!-- END OF FORM -->
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import FlowHeader from "@/components/FlowHeader";
import emailjs from "@emailjs/browser";
import {SERVICE_ID, USER_ID} from "@/plugins/email";

const TEMPLATE_ID = 'flow_template'
const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export default {
  components: {
    FlowHeader,
    // PhoneField,
  },
  data: () => ({
    isLoading: false,
    formIsValid: false,
    firstName: null,
    firstNameRules: [(v) => !!v || "First name is required"],
    lastName: null,
    lastNameRules: [(v) => !!v || "Last name is required"],
    email: null,
    emailRules: [
      (v) => !!v || "Email is required",
      (v) =>
        emailPattern.test(v) ||
        "Enter a valid email.",
    ],
    // phone: null,
  }),
  mounted() {
    this.firstName = this.$store.state.firstName;
    this.lastName = this.$store.state.lastName;
    this.email = this.$store.state.email;
  },
  methods: {
    tryAndStoreContactInfoAndContinue() {
      this.$refs.form.validate()

      if (this.formIsValid) {
        this.$store.commit("setFirstName", this.firstName);
        this.$store.commit("setLastName", this.lastName);
        this.$store.commit("setEmail", this.email);

        this.isLoading = true
        emailjs.send(SERVICE_ID, TEMPLATE_ID, this.$store.state , USER_ID)
          .then((result) => {
            console.log('Email Sent', result.text);
            this.isLoading = false
            this.$router.push("/success");
            this.$store.commit('reset')
            window.scrollTo(0, 0);
          }, (error) => {
            console.log('Email Failed...', error.text);
            this.isLoading = false
          });
      }
    },
  },
};
</script>
