<template>
  <v-container style="max-width: 1100px">
    <v-row class="mx-4">
      <v-col class="my-sm-10 d-flex justify-center">
        <v-img
          alt="testimonial"
          class="mx-10 mb-16"
          style="height: 100%"
          :max-width="$vuetify.breakpoint.mdAndUp ? '349' : '100%'"
          :src="require('@/assets/images/' + imageName + '')"
          contain
          eager
        />
      </v-col>
      <v-col class="d-flex flex-column justify-center">
        <div class="d-flex">
          <div class="text-h3 accent--text mr-2">{{ number }}</div>
          <div>
            <div class="text-h3">
              {{ title }}
            </div>
            <div class="text-body-2 mt-5">
              {{ text }}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    imageName: String,
    number: String,
    title: String,
    text: String,
  },
};
</script>