<template>
  <layout-dialog
    ref="layoutDialog"
    title="Terms of Use"
    subtitle="SPONTAY, LLC"
  >
    <div
      id="policies"
      name="termly-embed"
      data-id="5deb2c8d-8d1c-440d-9bee-24678c8c905e"
      data-type="iframe"
    />

  </layout-dialog>
</template>

<script>
import LayoutDialog from "./LayoutDialog";

export default {
  components: {
    LayoutDialog
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    show() {
      this.$refs.layoutDialog.show()
      this.$nextTick(() => {
        const termsAndConditions = document.getElementById('policies')
        let js = document.createElement('script')
        js.id = 'termly-jssdk'
        js.src = "https://app.termly.io/embed-policy.min.js";

        termsAndConditions.parentNode.insertBefore(js, termsAndConditions);
      })
    }
  },
}
</script>

<style scoped>

</style>