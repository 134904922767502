<template>
  <v-app class="myFont" v-resize="onWindowResize">
    <coinvesting ref="coinvesting" />
    <privacy-policy ref="privacyPolicy" />
    <terms-of-use ref="termsOfUse" />
    <insurance ref="insurance" />
    <v-container ref="topBar" fluid v-if="onDefaultLayout">
      <v-row style="background-color: #ffc001">
        <v-col class="d-flex justify-center align-start">
          <v-icon color="#C95555" class="mr-2">mdi-heart</v-icon>
          <div>
            Do you love your house? We do too. We are a home insurance company
            that’s invested into your home. We can offer you up to $100,000.
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- HEADER -->
    <v-app-bar
      ref="appBar"
      color="white"
      clipped-left
      :height="$vuetify.breakpoint.xs ? '66' : '88'"
      elevation="0"
      :fade-img-on-scroll="onDefaultLayout"
      :src="onDefaultLayout ? require('./assets/images/app-bar.svg') : ''"
      :class="bannerVisible ? 'stickynav' : !onDefaultLayout ? 'stickynav' : ''"
    >
      <v-container class="fill-height py-0 px-0 px-sm-10">
        <router-link id="header-logo" to="/">
          <v-img
            :width="$vuetify.breakpoint.xs ? '150' : '170'"
            src="@/assets/images/logo.svg"
            contain
            @click="$vuetify.goTo(0)"
          />
        </router-link>

        <v-spacer></v-spacer>

        <div class="d-none d-sm-flex" v-if="onDefaultLayout">
          <router-link to="/" class="link">
            <v-btn plain x-large @click="$vuetify.goTo(0)">Home</v-btn>
          </router-link>
          <v-btn plain x-large @click="$vuetify.goTo('#how-it-works')">
            How it Works
          </v-btn>
          <v-btn plain x-large @click="$refs.coinvesting.show()"> Learn </v-btn>
        </div>

        <v-app-bar-nav-icon
          v-if="onDefaultLayout"
          class="d-flex d-sm-none"
          @click="drawer = true"
        ></v-app-bar-nav-icon>

        <div class="align-center d-none d-md-flex" v-if="isFlowPage">
          <!-- <v-icon color="secondery" class="mr-1">mdi-map-marker-outline</v-icon> -->
          <p
            style="
              color: #4c4c4c;
              font-size: 18px;
              font-weight: 400;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 600px;
            "
            class="mb-0"
          >
            {{ this.$store.state.address }}
          </p>
        </div>
      </v-container>
    </v-app-bar>

    <!-- MOBILE NAVIGATION -->
    <v-navigation-drawer clipped v-model="drawer" app temporary>
      <v-list nav>
        <v-list-item-group v-model="group" color="indigo">
          <v-list-item>
            <router-link id="header-logo" to="/">
              <v-img
                :width="$vuetify.breakpoint.xs ? '150' : '170'"
                src="@/assets/images/logo.svg"
                class="ml-1"
                contain
                @click="$vuetify.goTo(0)"
              />
            </router-link>
          </v-list-item>
          <v-list-item>
            <v-btn plain x-large> Home</v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn plain x-large @click="$vuetify.goTo('#how-it-works')">
              How it Works
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn plain x-large @click="$refs.coinvesting.show()">
              Learn</v-btn
            >
          </v-list-item>
            <router-link
              style="text-decoration: none"
              to="/address"
              class="d-flex d-sm-none mt-5"
            >
              <v-btn
                x-large
                class="spontay-button ml-2"
                color="accent"
                rounded
                elevation="0"
              >
                Get Started
              </v-btn>
            </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- Coinvesting Dialog -->

    <!-- MAIN -->
    <v-main v-scroll="onScroll">
      <v-progress-linear
        style="position: fixed; z-index: 1"
        class="offset"
        v-if="!onDefaultLayout"
        v-bind:value="myProgress()"
        :style="$route.name !== 'Home' ? 'border-top: 1px solid #D8D8D8;' : ''"
        height="6"
        color="accent"
        background-color="#FFFEF2"
      >
      </v-progress-linear>
      <v-container
        fluid
        class="pa-0"
        :class="bannerVisible ? 'offset' : !onDefaultLayout ? 'offset' : ''"
      >
        <router-view
          @show-coinvesting="$refs.coinvesting.show()"
          @show-privacy-policy="$refs.privacyPolicy.show()"
          @show-terms-of-use="$refs.termsOfUse.show()"
          @show-insurance="$refs.insurance.show()"
        />
        <v-btn
          v-if="!onDefaultLayout && $route.name !== 'success'"
          elevation="2"
          fixed
          color="#fff"
          @click="$router.go(-1)"
          fab
          class="ml-4"
          :style="
            $vuetify.breakpoint.mdAndDown
              ? 'bottom: 16px; right: 16px'
              : 'top:50%'
          "
        >
          <v-icon large color="gray">mdi-chevron-left</v-icon>
        </v-btn>
      </v-container>
    </v-main>

    <spontay-footer
      v-if="onDefaultLayout"
      @show-coinvesting="$refs.coinvesting.show()"
      @show-privacy-policy="$refs.privacyPolicy.show()"
      @show-terms-of-use="$refs.termsOfUse.show()"
      @show-insurance="$refs.insurance.show()"
    />
  </v-app>
</template>

<script>
import SpontayFooter from "./components/SpontayFooter";
import Coinvesting from "./components/Coinvesting";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import Insurance from "./components/Insurance";

export default {
  components: {
    SpontayFooter,
    Coinvesting,
    PrivacyPolicy,
    TermsOfUse,
    Insurance,
  },
  data: () => ({
    drawer: false,
    group: null,
    isFlow: null,
    offsetTop: 0,
    topBannerHeight: 0,
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
  mounted() {
    this.onWindowResize();
  },
  computed: {
    onDefaultLayout() {
      return this.$route.meta.defaultLayout;
    },
    bannerVisible() {
      return this.offsetTop > this.topBannerHeight;
    },
    isFlowPage() {
      return !this.$route.meta.defaultLayout;
    },
  },
  methods: {
    myProgress() {
      const routesProgress = {
        Address: 3,
        ownership: 20,
        furnishing: 40,
        propertypurpose: 60,
        contactinformation: 80,
        success: 100,
      };
      return routesProgress[this.$route.name] || 0;
    },
    onWindowResize() {
      if (this.$refs.topBar) {
        this.topBannerHeight = this.$refs.topBar.clientHeight;
      }
    },
    onScroll() {
      this.offsetTop = window.scrollY;
    },
    nullScroll() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.link {
  text-decoration: none;
}

.stickynav {
  position: fixed;
  z-index: 3;
 
}

.offset {
  margin-top: 88px;
}

@media (max-width: 600px) {
  .offset {
    margin-top: 66px;
  }
}
</style>