<template>
  <v-container fluid class="pa-0" id="cta">
    <!-- HERO -->
    <v-container fluid class="pb-6 pt-6 pb-sm-2 pt-sm-10" style="background-color: #fffef2">
      <v-container>
        <v-row style="max-width: 870px" class="mx-auto">
          <v-col>
            <div class="text-h1 text-center">
              We are your partners in caring for your home
            </div>
            <v-form
              class="d-none d-sm-flex flex-column flex-sm-row mt-10"
              v-model="formIsValid"
            >
              <vuetify-google-autocomplete
                id="map"
                label="What’s your home address?"
                placeholder=""
                v-model="addressName"
                :rules="addressRules"
                class="mr-10"
                :class="
                  $vuetify.breakpoint.xs
                    ? 'rounded-lg'
                    : ' rounded-l-lg rounded-r-0'
                "
                prepend-icon="mdi-map-marker-outline"
                @placechanged="addressObtained"
                @no-results-found="noResultObtained"
              />
              <v-btn
                elevation="0"
                x-large
                class="px-10 spontay-btn spontay-button"
                @click="tryToSaveAndContinue"
                width="230"
                color="accent"
                height="55"
                rounded
              >
                Get Started
              </v-btn>
            </v-form>
            <router-link
              style="text-decoration: none"
              to="/address"
              class="d-flex d-sm-none mt-8"
            >
              <v-btn
                x-large
                class="spontay-button"
                block
                color="accent"
                rounded
                elevation="0"
              >
                Get Started
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-container>

      <v-row class="mt-10">
        <v-col>
          <v-img
            alt="home"
            eager
            class="mx-auto mb-16"
            src="@/assets/images/house.png"
            max-width="980"
            contain
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- HOW IT WORKS -->
    <v-container id="how-it-works" class="px-0 py-sm-10 mb-10 mb-sm-0" fluid>
      <section-title
        title="Spontay is the first insurance company  investing  in your house "
        subtitle="HOW IT WORKS"
      >
      </section-title>

      <how-it-works
        imageName="step-1.svg"
        number="1."
        title="Don’t wait for an accident, get money upfront"
        text="You’ll get up to $100,000 in cash at the very beginning of our partnership. Think about it as a payment for the insurance claim, before the damage even happens."
      ></how-it-works>

      <v-divider class="my-16"></v-divider>

      <how-it-works
        imageName="step-2.svg"
        number="2."
        title="We are your partner"
        text="We become co-investors in your home. That means whatever happens - good or bad - we are right there with you, sharing the consequnces."
      ></how-it-works>

      <v-divider class="my-16"></v-divider>

      <how-it-works
        imageName="step-3.svg"
        number="3."
        title="We value trust"
        text="We really do care. Because it affects us, just as much as it affects you. We’ll proactively let you know about any needed improvements, risks, or changes."
      ></how-it-works>
    </v-container>

    <!-- HOW TO APPLY -->

    <v-container
      class="py-sm-16 px-6 pb-16"
      fluid
      style="background-color: #fffef2"
    >
      <section-title
        title="From the start to the finish line"
        subtitle="HOW TO APPLY"
      >
      </section-title>

      <v-card
        elevation="0"
        rounded="0"
        class="py-10 py-sm-16 pr-6 px-sm-6 mx-auto mb-16 mt-10"
        color="#FFFEF2"
        max-width="600"
        style="border: 2px solid #4683d0;"
      >
        <v-timeline align-top dense>
          <v-timeline-item fill-dot color="indigo" 
            ><template v-slot:icon>
              <span style="color: white">1</span>
            </template>
            <div class="text-body-1">
              Go through our digital application process
            </div>
            <v-btn
              class="mt-3 spontay-button"
              color="accent"
              rounded
              large
              elevation="0"
              @click="$vuetify.goTo(0)"
            >
              Get Started
            </v-btn>
          </v-timeline-item>
          <v-timeline-item fill-dot class="pb-13" color="indigo"
            ><template v-slot:icon>
              <span style="color: white">2</span>
            </template>
            <div class="text-body-1">
              We’ll contact you to figure out all the details
            </div>
          </v-timeline-item>
          <v-timeline-item fill-dot class="pb-13" color="indigo"
            ><template v-slot:icon>
              <span style="color: white">3</span>
            </template>
            <div class="text-body-1">We’ll sort out all the paperwork</div>
          </v-timeline-item>
          <v-timeline-item fill-dot color="indigo"
            ><template v-slot:icon>
              <span style="color: white">3</span>
            </template>
            <div class="text-body-1">
              We’ll transfer the money into your account
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-card>

      <contact-us></contact-us>
    </v-container>

    <testimonials></testimonials>
  </v-container>
</template>

<script>
import HowItWorks from "@/components/HowItWorks";
import SectionTitle from "../components/SectionTitle";
import Testimonials from "../components/Testimonials";
import ContactUs from "../components/ContactUs";

export default {
  components: { HowItWorks, SectionTitle, Testimonials, ContactUs },
  data: () => ({
    formIsValid: false,
    addressName: null,
    address: {},
  }),
  computed: {
    addressRules() {
      return [(v) => !!v || "Address is Required"];
    },
  },
  mounted() {
    if (this.$route.query.map) {
      this.addressName = this.$route.query.map;
    }
  },
  // Store Address and Continue
  methods: {
    addressObtained(address) {
      this.address = address;
    },
    noResultObtained() {},
    tryToSaveAndContinue() {
      if (this.formIsValid) {
        this.$store.commit("setAddress", this.addressName);
        this.$router.push("/ownership");
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>