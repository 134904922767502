import Vue from 'vue'
import App from './App'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import "@/assets/scss/global-style.scss"
import Vuelidate from 'vuelidate'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

Vue.use(Vuelidate)

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: 'AIzaSyBE5T2T1k5Dbawjv7xXsrE0H7DZcWwFtJI', // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
});

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');