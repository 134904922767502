<template>
  <v-container fluid class="pa-0 ma-0">
    <v-container>
      <flow-header title="Here’s what we can do for you"></flow-header>
      <v-card elevation="0" class="pt-0 pt-sm-3">
        <v-card-actions class="mt-7">
          <v-row>
            <v-col>
              <v-card color="#F5F7FA" class="pa-5 rounded-lg" elevation="0">
                <v-col
                >
                  <v-img
                    alt="spontay-logo"
                    src="@/assets/images/icon-7.svg"
                    class="shrink"
                    height="44"
                    contain
                    position="left"
                  />
                </v-col>
                <v-card-title class="spontay-card-title"
                >We can invest in your house
                </v-card-title
                >
                <v-card-text
                >Based on all the information we’ve got, the cash equivalent
                  we can offer you ranges from
                  <b>$7’600 - 12’600</b></v-card-text
                >
                <v-card-actions class="pa-4">
                  <v-btn
                    class="spontay-button"
                    :width="$vuetify.breakpoint.xs ? '' : '160'"
                    x-large
                    @click="storeOfferAndContinue('Co-Investing')"
                    color="accent"
                  >
                    I’m interested
                  </v-btn>
                  <v-btn
                    class="spontay-button spontay-button-secondary"
                    color="white"
                    :width="$vuetify.breakpoint.xs ? '' : '160'"
                    x-large
                    @click="$emit('show-coinvesting')"
                  >
                    Learn More
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col>
              <v-card color="#F5F7FA" class="pa-5 rounded-lg" elevation="0">
                <v-col
                >
                  <v-img
                    alt="spontay-logo"
                    src="@/assets/images/icon-8.svg"
                    class="shrink"
                    height="44"
                    contain
                    position="left"
                  />
                </v-col>
                <v-card-title class="spontay-card-title"
                >We can provide home insurance
                </v-card-title
                >
                <v-card-text
                >Based on the information already provided it would vary
                  between <b>$82-130</b> / per month (depending on the level of
                  coverage).
                </v-card-text
                >
                <v-card-actions class="pa-4">
                  <v-btn
                    x-large
                    class="spontay-button"
                    :width="$vuetify.breakpoint.xs ? '' : '160'"
                    @click="storeOfferAndContinue('Home Insurance')"
                    color="accent"
                  >
                    I’m interested
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import FlowHeader from "@/components/FlowHeader";

export default {
  components: {FlowHeader},

  data: () => ({
    service: null,
  }),
  methods: {
    storeOfferAndContinue(option) {
      this.$store.commit("setService", option);
      this.$router.push("/contactinformation");
      window.scrollTo(0, 0);
    },
  },
};
</script>