<template>
  <v-container fluid class="flowbody">
    <v-container>
      <flow-header title="What's your home address?"></flow-header>
      <v-card elevation="0" color="lightgray" class="pt-0 pt-sm-10">
        <div class="px-2">
          <v-form class="mt-6" v-model="formIsValid">
            <vuetify-google-autocomplete
              id="map"
              label="Address"
              placeholder=""
              class="mr-3"
              v-model="addressName"
              :rules="addressRules"
              :class="
                $vuetify.breakpoint.xs
                  ? 'rounded-lg'
                  : ' rounded-l-lg rounded-r-0'
              "
              prepend-icon="mdi-map-marker-outline"
              @placechanged="addressObtained"
              @no-results-found="noResultObtained"
            />
            <v-btn
              elevation="0"
              x-large
              block
              class="px-10 spontay-btn spontay-button mt-4"
              @click="tryToSaveAndContinue"
              width="230"
              color="accent"
              rounded
            >
              Get Started
            </v-btn>
          </v-form>
        </div>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import FlowHeader from "@/components/FlowHeader";

export default {
  components: { FlowHeader },
  data: () => ({
    address: {},
    formIsValid: false,
  }),
  computed: {
    addressRules() {
      return [(v) => !!v || "Address is Required"];
    },
  },
  mounted() {
    if (this.$route.query.map) {
      this.addressName = this.$route.query.map;
    }
  },
  methods: {
    addressObtained(address) {
      this.address = address;
    },
    noResultObtained() {},
    tryToSaveAndContinue() {
      if (this.formIsValid) {
        this.$store.commit("setAddress", this.addressName);
        this.$router.push("/ownership");
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>

