import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const getDefaultState = () => ({
  address: null,
  ownership: null,
  furnishing: null,
  purpose: null,
  // service: null,
  firstName: null,
  lastName: null,
  email: null,
  message: null,
})

export default new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    setAddress(state, address) {
      state.address = address
    },
    setOwnership(state, ownership) {
      state.ownership = ownership
    },
    setFurnishing(state, furnishing) {
      state.furnishing = furnishing
    },
    setPurpose(state, purpose) {
      state.purpose = purpose
    },
    // setService(state, service) {
    //   state.service = service
    // },
    setFirstName(state, firstName) {
      state.firstName = firstName
    },
    setLastName(state, lastName) {
      state.lastName = lastName
    },
    setEmail(state, email) {
      state.email = email
    },
    setMessage(state, message) {
      state.message = message
    },
    reset(state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {},
  modules: {}
})
