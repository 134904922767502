import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../pages/Home";
import Ownership from "../pages/Ownership";
import Furnishing from "../pages/Furnishing";
import PropertyPurpose from "../pages/PropertyPurpose";
import Offer from "../pages/Offer";
import ContactInformation from "../pages/ContactInformation";
import Test from "../pages/Test";
import Success from "../pages/Success"
import Address from "../pages/Address"
import Contact from "../pages/Contact";

Vue.use(VueRouter);


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      defaultLayout: true
    }
  },
  {
    path: "/address",
    name: "Address",
    component: Address,
  },

  {
    path: "/ownership",
    name: "ownership",
    component: Ownership,
  },
  {
    path: "/furnishing",
    name: "furnishing",
    component: Furnishing,
  },
  {
    path: "/propertypurpose",
    name: "propertypurpose",
    component: PropertyPurpose,
  },
  {
    path: "/offer",
    name: "offer",
    component: Offer,
  },
  {
    path: "/contactinformation",
    name: "contactinformation",
    component: ContactInformation,
  },
  {
    path: "/test",
    name: "test",
    component: Test,
  },
  {
    path: "/success",
    name: "success",
    component: Success,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta: {
      defaultLayout: true
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
