import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#4683D0',
                secondary: '#4683D0',
                indigo: '#0A0F1E',
                accent: '#FFC001',
                error: '#FF5252',
                info: '#8C9094',
                success: '#A4CE4E',
                warning: '#FFC107',
                gray: '#4C4C4C',
                lightgray: '#FFFEF2'
            },
        },
    },
});
