<template>
  <v-container fluid class="flowbody pa-0">
    <v-container class="pt-0">
      <flow-header
        title="We’ll contact you to discuss the details"
        subtitle="Type in your contact info below"
      ></flow-header>
      <v-card color="lightgray" elevation="0" class="pt-sm-4 pt-0">
        <!-- FORM -->
        <v-card-actions class="mt-4">
          <div style="max-width: 600px; margin: auto">
            <v-form ref="form" v-model="formIsValid" lazy-validation>
              <v-row>
                <v-col>
                  <v-text-field
                    autofocus
                    v-model="firstName"
                    :rules="firstNameRules"
                    label="First Name"
                    name="firstName"
                    required
                    @keyup.enter="$refs.lastName.focus()"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    ref="lastName"
                    v-model="lastName"
                    :rules="lastNameRules"
                    label="Last Name"
                    name="lastName"
                    required
                    @keyup.enter="$refs.email.focus()"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                ref="email"
                v-model="email"
                :rules="emailRules"
                label="Email Address"
                name="email"
                required
                @keyup.enter="$refs.message.focus()"
              >
              </v-text-field>
              <v-textarea
                ref="message"
                v-model="message"
                :rules="messageRules"
                label="Message"
                name="message"
                rows="1"
                auto-grow
              >
              </v-textarea>

              <v-btn
                class="spontay-button mt-4"
                x-large
                block
                color="accent"
                :loading="isLoading"
                @click="tryAndStoreContactInfoAndContinue"
              >
                Continue
              </v-btn>
            </v-form>
          </div>
        </v-card-actions>
        <!-- END OF FORM -->
        <v-snackbar
          v-model="successVisible"
          color="success"
          elevation="0"
          class="pb-4"
        >
          Your message is successfully sent

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="successVisible = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import FlowHeader from "@/components/FlowHeader";
import emailjs from "@emailjs/browser";
import { SERVICE_ID, USER_ID } from "@/plugins/email";

const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const TEMPLATE_ID = "contact_template";

export default {
  components: {
    FlowHeader,
    // PhoneField,
  },
  data: () => ({
    isLoading: false,
    successVisible: false,
    formIsValid: true,
    firstName: "",
    firstNameRules: [(v) => !!v || "First name is required"],
    lastName: "",
    lastNameRules: [(v) => !!v || "Last name is required"],
    email: "",
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => emailPattern.test(v) || "Enter a valid email.",
    ],
    message: "",
    messageRules: [
      (v) =>
        (!!v && String(v).length > 9) ||
        "Message needs to be at least 10 symbols",
    ],
  }),
  methods: {
    tryAndStoreContactInfoAndContinue() {
      this.$refs.form.validate();
      if (this.formIsValid) {
        this.isLoading = true;
        emailjs
          .sendForm(SERVICE_ID, TEMPLATE_ID, this.$refs.form.$el, USER_ID)
          .then(
            (result) => {
              console.log("Email Sent", result.text);
              this.isLoading = false;
              this.$refs.form.reset();
              this.successVisible = true;
            },
            (error) => {
              console.log("Email Failed...", error.text);
              this.isLoading = false;
            }
          );
      }
    },
  },
};
</script>
